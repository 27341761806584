#artist-details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: white;
  overflow: hidden;
}

#artist-details-wrapper > h2 {
  color: rgba(0, 0, 0, 0.3);
}

#artist-details-section {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 70vw;
  min-height: 60vh;
  margin-top: 1rem;
  align-items: center;
  border-radius: 1rem;
  box-shadow: var(--box-shadow2);
  margin-bottom: 2rem;
}

#artist-details-section > span {
  height: 15vh;
  width: 100%;
  background-image: linear-gradient(0deg, var(--blue-m), var(--blue-dark));
  border-radius: 1rem 1rem 0 0;
  box-shadow: var(--box-shadow2);
  overflow: hidden;
}

#artist-details-section > img {
  display: flex;
  aspect-ratio: 1/1;
  max-width: 10vw;
  max-height: 10vw;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  margin-top: -4rem;
  border: unset;
  box-shadow: var(--box-shadow2);
  border: 0.5rem solid white;
}

#artist-details-section > nav {
  display: flex;
  position: absolute;
  top: 1rem;
  right: -6rem;
}

#artist-details-section > h3 {
  margin-top: 1rem;
  position: relative;
}

#artist-details-section > p {
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

#artist-details-section > nav > ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#artist-details-section > nav > ul > li {
  font-size: 1.5rem;
  line-height: 0;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: var(--blue-dark);
  color: white;
  border: unset;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

#artist-details-section > nav > ul > li:hover {
  background-color: var(--blue-light);
  border-radius: 50%;
}

#related-plays-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 90vw;
  min-width: 50vw;
  justify-content: center;
  gap: 1rem;

  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 3rem;
}

#artist-details-scroll-up {
  background-image: linear-gradient(0deg, var(--blue-m), var(--blue-dark));
}

@media screen and (width < 1100px) {
  #artist-details-section {
    width: 50vw;
  }
  #artist-details-section > img {
    max-height: 170px;
    max-width: 170px;
  }
}

@media screen and (width < 800px) {
  #artist-details-section {
    width: 75vw;
  }
  #artist-details-section > nav {
    margin-right: 2rem;
  }
}

@media screen and (width < 700px) {
  #artist-details-section > p {
    font-size: 1.25rem;
  }
}

@media screen and (width < 600px) {
  #artist-details-section > nav > ul {
    flex-direction: row;
  }

  #artist-details-section > nav > ul > li {
    background-color: white;
    color: var(--blue-dark);
  }

  #artist-details-section > nav > ul > li:hover {
    background-color: var(--blue-lighter);
  }

  #artist-details-section > nav {
    top: 2rem;
    right: 10%;
    margin-bottom: 3rem;
  }

  #artist-details-section > span {
    height: 25vh;
  }
  #artist-details-section {
    margin-top: unset;
  }
}
