#profile-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  background-image: radial-gradient(var(--blue-light), var(--blue-m));
}

#profile-wrapper > h3:hover {
  color: var(--contrast-orange);
}

#profile-wrapper > nav > ul {
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.3);
  width: 85vw;
  justify-content: space-evenly;
  padding: 1.25rem;
  color: white;
  align-items: center;
  border-radius: 1rem;
  margin: 10vh;
  box-shadow: var(--box-shadow);
}

#profile-wrapper > nav > ul > li {
  font-size: 1.25rem;
  cursor: pointer;
}

#profile-wrapper > nav > ul > li:nth-child(2) {
  margin-right: 5rem;
}

#profile-wrapper > nav > ul > li:nth-child(4) {
  margin-left: 5rem;
}

#profile-wrapper > nav > ul > li:nth-child(3) {
  display: flex;
  width: 10vw;
  height: 10vw;
  background-image: linear-gradient(var(--blue-m), var(--blue-dark));
  position: absolute;
  border: 0.25rem solid rgb(226, 226, 226);
  border-radius: 1rem;
  overflow: hidden;
  cursor: default;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  line-height: 0;
}

#profile-wrapper > nav > ul > li:nth-child(1):hover,
#profile-wrapper > nav > ul > li:nth-child(2):hover,
#profile-wrapper > nav > ul > li:nth-child(4):hover,
#profile-wrapper > nav > ul > li:nth-child(5):hover {
  color: var(--blue-dark);
  font-weight: 600;
}

#permissions-info {
  background-color: white;
  width: 80vw;
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  padding: 1rem;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  box-shadow: var(--box-shadow);
}

#permissions-info > h5 {
  line-height: unset;
  margin-left: 1rem;
  color: var(--contrast-orange);
  padding: 0.5rem 0 0.2rem 0;
}

#permissions-info > button {
  color: var(--blue-lighter);
  background-color: var(--blue-dark);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: unset;
  font-size: 1rem;
}

#permissions-info > button:hover {
  color: white;
  background-color: var(--blue-m);
}

#profile-error {
  display: grid;
  margin: auto;
  gap: 1rem;
  padding: 2rem 3rem;
  border-radius: 1rem;
  background-color: white;
}

@media screen and (width < 900px) {
  #profile-wrapper > nav > ul > li:nth-child(3) {
    display: none;
  }

  #profile-wrapper > nav > ul > li:nth-child(2) {
    margin-right: unset;
  }

  #profile-wrapper > nav > ul > li:nth-child(4) {
    margin-left: unset;
  }

  #profile-wrapper > nav > ul {
    width: 90vw;
  }

  #permissions-info {
    width: 90vw;
  }
}

@media screen and (width < 660px) {
  #profile-wrapper > nav > ul {
    margin: 2rem;
  }

  #permissions-info {
    gap: 1rem;
  }
}
