#event-details-view-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  /* justify-content: center; */
  align-items: center;
}

#event-details-card > article > h1 {
  font-size: 1.75rem;
}
#event-details-nav {
  /* temp */
  background-color: var(--blue-m);
  width: 100%;
}

#event-details-nav > ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

#event-details-nav > ul > li {
  font-size: 2rem;
  color: white;
  background-color: var(--blue-dark);
  line-height: 0;
  padding: 1rem;
  border-radius: 50%;
}

#event-details-nav > ul > li:hover {
  color: var(--blue-dark);
  background-color: white;
}

#event-details-card {
  display: grid;
  padding: 2rem 3rem;
  border-radius: 1rem;
  width: 80vw;
  margin: 1rem auto;
  grid-template-columns: 2fr 1fr;
  box-shadow: var(--box-shadow);
}

#event-details-card > article {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

#event-details-card > span {
  display: flex;
  border-radius: 1rem;
  padding: 1rem;
}

#event-details-card > span > img {
  min-height: 40vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-top: -7rem;
  border: 1rem solid white;
  box-shadow: var(--box-shadow);
}

#event-details-tickets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ticket-price-span {
  display: flex;
  flex-direction: column;
  width: 80vw;
  border-radius: 1rem;
  padding: 2rem 4rem 2rem 3rem;
  box-shadow: var(--box-shadow);
}

#ticket-price-span span > h4 {
  font-size: 1.25rem;
  color: var(--blue-m);
}

#ticket-price-span span {
  display: grid;
  margin: 1rem 0 0 0;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  gap: 1rem;
}

#ticket-price-span > span > button {
  min-height: 3rem;
  margin-bottom: 0.5rem;
  background-image: linear-gradient(#425a7db0, var(--blue-m));
  color: white;
  border-radius: 0.5rem;
  border: unset;
}

#event-details-tickets > span > button:hover {
  background-image: linear-gradient(#2a3240bc, var(--blue-dark));
}

#artist-minireel-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

#artist-minireel-title > h3 {
  margin: 1.5rem 0;
  color: rgba(0, 0, 0, 0.4);
}

#artists-minireel {
  display: flex;
  min-height: 10vh;
  flex-wrap: wrap;
  min-width: 90vw;
  justify-content: center;
  gap: 1rem;
  margin: 0rem 0 3rem 0;
}

@media screen and (width < 1200px) {
  #event-details-card {
    grid-template-columns: 1fr;
  }

  #event-details-card > article > h1 {
    font-size: 2rem;
  }

  #event-details-card > span > img {
    top: 0rem;
    max-height: 65vh;
    margin-top: 1rem;
  }

  #event-details-card > span {
    padding: unset;
    margin-top: 2rem;
  }

  #ticket-price-span {
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (width < 600px) {
  #event-details-card {
    padding: 1rem;
  }

  #event-details-card > article > p {
    padding: 0 0.25rem;
  }
  #artist-minireel-title > h3 {
    margin: 3rem 0 2rem 0;
  }

  #event-details-card > span {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
