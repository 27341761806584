header {
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
  align-items: center;
  min-height: 15vh;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  box-shadow: var(--box-shadow);
  position: relative;
}

header > svg {
  cursor: pointer;
  font-size: 3rem;
  position: relative;
}

header > svg:hover {
  color: var(--blue-m);
}

@media screen and (width < 1100px) {
  header {
    padding: 0 4rem;
    /* flex-direction: column; */
  }
}
