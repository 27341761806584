#artist-minimal {
  display: flex;
  flex-direction: column;
  position: relative;
  /* min-width: 9rem; */
}

#artist-minimal > p:hover {
  color: var(--contrast-orange);
}

#artist-minimal > a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  background-image: linear-gradient(90deg, var(--blue-m), var(--blue-dark));
}

#artist-minimal > a > img {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  object-fit: contain cover;
  outline: 0.2rem solid var(--blue-dark);
}

#artist-minimal > p {
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--blue-m);
  /* width: 100%;
  height: 100%; */
}
