#chart-section-wrapper {
  min-height: 33vh;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
  margin-bottom: 3rem;
}

#chart-section-wrapper > span {
  padding: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  max-height: 50vh;
}
