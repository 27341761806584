#announcement-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#announcement-wrapper > span {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.5rem;
  align-items: center;
  width: 100vw;
  background-color: var(--blue-dark);
  background-image: linear-gradient(180deg, var(--blue-dark), var(--blue-m));
  padding: 3rem 4rem;
  box-shadow: var(--box-shadow2);
}

#announcement-wrapper > span > h4 {
  font-size: 2rem;
  color: var(--blue-lighter);
}

#announcement-wrapper > span > p {
  font-size: 1.5rem;
  color: var(--blue-lighter);
  font-weight: 500;
  /* align-self: flex-end; */
}

#announcement-wrapper > span > p:nth-child(2) {
  color: var(--blue-light);
}

#announcement-wrapper > span > a {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--blue-dark);
  color: var(--blue-light);
  border: unset;
  cursor: pointer;
  margin-top: 1rem;
}

#announcement-wrapper > span > a:hover {
  color: white;
  background-color: var(--contrast-orange);
}

@media screen and (width < 1100px) {
  #announcement-wrapper > span > h5 {
    width: 100%;
    font-size: 1rem;
    padding: unset;
  }

  #announcement-wrapper > span > h4 {
    width: 100%;
  }
}
