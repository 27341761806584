.play-hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 0.5rem;
}

.play-hero > .properties > img {
  height: 210px;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  object-fit: cover;
}

.play-hero > .properties > h3 {
  padding: 0 1rem;
  margin-bottom: 0.3rem;
  font-family: "Oswald";
}

.play-hero > .properties > h4,
h5 {
  padding: 0.1rem 1rem;
  color: var(--blue-m);
}

.play-hero > .properties > p {
  /* top right bottom left */
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.hero-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  margin: 0 1rem 0.5rem 0;
}

.hero-buttons > a {
  color: var(--blue-dark);
  cursor: pointer;
}

.hero-buttons > a > button {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  background-color: transparent;
  border: 0.1rem solid transparent;
  border-radius: 50%;
  padding: 0.5rem;
  color: var(--blue-light);
  margin: 0.5rem 0;
  cursor: pointer;
}

.hero-buttons > a > button:hover {
  border: 0.1rem solid var(--blue-m);
  color: var(--blue-m);
}

/* .hero-buttons > a > button {
  color: inherit;
  line-height: 0;
} */

.info-btn {
  line-height: 0;
}
