#logout-bg {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(white, var(--blue-lighter));
}

#logout-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem 5rem;
  box-shadow: var(--box-shadow2);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 30vw;
}

#logout-wrapper > h1 {
  color: var(--blue-dark);
  padding: 2rem;
  font-size: 2.5rem;
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

#logout-wrapper > span {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

#logout-wrapper > span > button {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--blue-dark);
  color: white;
  border: unset;
  cursor: pointer;
  width: 100%;
}

#logout-wrapper > span > button:hover {
  color: var(--blue-lighter);
  background-color: var(--blue-m);
}
