#seat-wrapper {
  display: flex;
  line-height: 0;
  padding: 0.5rem;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  cursor: pointer;
}

#seat-wrapper:hover {
  background-color: var(--blue-lighter);
}

#seat-wrapper > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#seat-wrapper > span > button {
  background-color: transparent;
  padding: 0.25rem;
  cursor: pointer;
  border: unset;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
}

@media screen and (width < 660px) {
  #seat-wrapper {
    padding: 0.4rem;
  }

  #seat-wrapper > span > button {
    padding: 0.2rem;
  }
}

@media screen and (width < 580px) {
  #seat-wrapper > span > button {
    padding: 0.1rem;
  }
}
