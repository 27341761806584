#hero-section {
  display: grid;
  grid-template-columns: 60% 40%;
  min-height: 40vh;
  padding: 2rem 3rem;
  background-color: rgba(96, 96, 96, 0.1);
}

#hero-section > span {
  padding: 2rem 0 2rem 5rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

#hero-section > img {
  padding: 3rem 5rem 3rem 2rem;
  height: 100%;
}

#hero-section > span > h1 {
  color: var(--blue-m);
  font-size: 3rem;
}
#hero-section > span > p {
  color: var(--blue-dark);
  font-size: 1.5rem;
}

#hero-box-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

#hero-box-buttons > button {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--blue-dark);
  color: white;
  border: unset;
  cursor: pointer;
}

#hero-box-buttons > button:hover {
  background-color: var(--blue-m);
  color: var(--blue-lighter);
}

#hero-box-buttons > a {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: white;
  border: 0.1rem solid var(--blue-dark);
  color: var(--blue-m);
  cursor: pointer;
}

#hero-box-buttons > a:hover {
  background-color: var(--blue-m);
  color: var(--blue-lighter);
}

@media screen and (width < 1100px) {
  #hero-section > span {
    padding: 1rem 0 1rem 3rem;
  }
  /* #hero-section > img {
    padding: 1rem 5rem 1rem 1rem;
  } */
}

@media screen and (width < 800px) {
  #hero-section > span {
    padding: 3rem 1rem;
    margin: auto;
  }
  #hero-section {
    grid-template-columns: 1fr;
    align-items: center;
    padding: 1rem 2rem;
  }

  #hero-section > img {
    height: 40vh;
    margin: auto;
    padding: unset;
  }
}
