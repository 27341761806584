#income-section-wrapper {
  min-height: 15vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: 100%;
  padding: 3rem 0;
}

#income-section-wrapper > span {
  padding: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
}

#income-section-wrapper > span > h1 {
  margin: 0 0 3rem 0;
}
