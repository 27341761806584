#selected-artist-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#selected-artist-wrapper > img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

#selected-artist-wrapper > span {
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--blue-m);
  width: 100%;
  height: 100%;
}
