main {
  /* max-width: 100vw; */

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  width: 100vw;

  /* overflow: hidden; */
}
