#calendar-view-wrapper {
  min-height: 100vh;
  width: 100%;
  display: grid;
  position: relative;
}

#calendar-view-wrapper > section:nth-child(1) {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* #calendar-view-wrapper > section:nth-child(2) {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */

#calendar-view-wrapper > section:nth-child(2) > article {
  padding: 2rem 3rem;
  color: white;
}

/*  */
.fc .fc-button-primary {
  text-transform: uppercase;
  font-family: "Nunito-Medium", sans-serif;
}

.fc .fc-button-primary:disabled {
  opacity: 0;
}

.fc-daygrid-dot-event {
  flex-direction: row;
  flex-wrap: wrap;
}

.fc-daygrid-dot-event {
  align-items: end !important;
}

.fc-daygrid-event-dot {
  height: 0.1rem;
  width: 80%;
  border: unset;
  background-color: var(--contrast-orange);
}

.fc-event-title {
  font-size: 1rem;
}

.fc-header-toolbar {
  background-color: var(--blue-m);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}

.fc-toolbar-chunk {
  margin-left: 2rem;
  color: var(--blue-lighter);
}

.fc .fc-button-primary {
  background-color: var(--blue-dark);
  border-color: var(--blue-dark);
  color: var(--blue-lighter);
}
.fc .fc-button {
  border: unset;
}

.fc-scrollgrid {
  background-color: white;
  border-radius: 0.5rem;
}

.fc-theme-standard th {
  background-color: #2c3e50;
  /* background-color: white; */
  overflow: hidden;
  padding: 0.5rem;
  border: unset;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.5rem 0.5rem 0 0;
}

/* shrinks the scrollbar a bit so the letters are readable */
/* .fc .fc-scroller-liquid-absolute {
  inset: -0.2rem;
} */

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  font-size: 1.125rem;
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 600;
  min-width: 0px;
  color: var(--blue-m);
  padding: 0.1rem 0;
  background-color: transparent;
}

/* new approach */

#Calendar > div {
  padding: 1.5rem;
}

#Calendar > div > div:nth-child(1) {
  margin-bottom: 1rem;
}

.fc .fc-daygrid-day-frame {
  padding: 0.2rem;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--blue-lighter);
}

.fc-event:hover {
  background-color: var(--blue-light);
}

.fc-event {
  display: grid;
  gap: 0.1rem;
  padding: 0.2rem;
}

.fc-scroller {
  overflow: hidden unset;
}

.fc-scroller {
  min-height: 2rem;
}

.fc-day-sun,
.fc-day-sat {
  background-color: rgba(223, 144, 26, 0.035);
}

.fc-today-button {
  text-transform: uppercase;
}

#calendar-view-details-modal {
  display: grid;
  z-index: 30;
  position: absolute;
  grid-template-columns: 1fr 1.5fr;
  width: 100%;
  min-height: 100%;
  justify-self: center;
  align-items: center;
  gap: 2rem;
  border-radius: 1rem;
  background-color: var(--blue-dark);
}

#calendar-view-details-modal > img {
  display: flex;
  width: 33vw;
  max-height: 50vh;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-left: 3rem;
  outline: 0.25rem solid var(--blue-m);
  box-shadow: var(--box-shadow2);
}

#calendar-view-details-modal > span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  padding: 2rem 0;
  margin-right: 3rem;
}

#calendar-view-details-modal > span > h1 {
  margin-bottom: 1.5rem;
}

#calendar-view-details-modal > span > p {
  font-size: 1.25rem;
}

#calendar-view-details-modal > span > h4 {
  color: var(--blue-light);
}
#calendar-view-details-modal > span > span {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

#calendar-view-details-modal > span > span > button {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: unset;
  box-shadow: var(--box-shadow2);
  background-color: var(--blue-m);
  color: var(--blue-lighter);
  font-size: 1rem;
  cursor: pointer;
}

#calendar-view-details-modal > span > span > button > a {
  line-height: 0;
}

#calendar-view-details-modal > span > span > button:nth-child(1):hover {
  background-color: var(--blue-lighter);
  color: var(--blue-dark);
}

#calendar-view-details-modal > span > span > button:nth-child(2):hover {
  background-color: var(--contrast-orange);
  color: black;
}

@media screen and (width < 880px) {
  #calendar-view-details-modal {
    max-width: 95vw;
    max-height: 95vh;
  }

  #calendar-view-details-modal > img {
    height: 50vh;
  }
}

@media screen and (width < 660px) {
  #calendar-view-details-modal {
    grid-template-columns: 1fr;
    padding: 2rem;
    height: auto;
    gap: 0;
  }

  #calendar-view-details-modal > img {
    width: 100%;
    margin-left: unset;
  }

  #calendar-view-details-modal > span {
    position: relative;
    margin-right: unset;
  }

  #calendar-view-details-modal > span > span > button {
    padding: 0.5rem 1.25rem;
    font-size: 1.2rem;
  }
}
