#header-navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  position: absolute;
  top: 10vh;
  right: 2rem;
  min-width: 25vw;
  z-index: 99;
  background-color: white;
  padding: 1.5rem 2.25rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow2);
}

#header-navigation > a {
  color: var(--blue-dark);
  font-size: 1.75rem;
  display: block;
}

#header-navigation > a:hover {
  color: var(--blue-light);
  /* text-decoration: underline;
  text-underline-offset: 0.25rem;
  text-decoration-thickness: 0.125rem; */
}

@media screen and (width < 1100px) {
  #header-navigation {
    top: 15vh;
    right: 3rem;
  }
}

@media screen and (width < 750px) {
  #header-navigation {
    gap: 0.5rem;
    top: 17vh;
    right: 2rem;
  }

  #header-navigation > a {
    font-size: 2rem;
  }
}
