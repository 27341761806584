.artist-card {
  height: 50vh;
  min-width: 350px;
  padding: 0 1.25rem;
  position: relative;
}

.artist-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  display: relative;
  box-shadow: var(--box-shadow);
  border: 0.4rem solid white;
}

.artist-card h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  padding: 1.5rem 3.5rem;
  color: white;
  text-shadow: var(--box-shadow);
}

.artist-card button {
  background-color: white;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 50%;
  line-height: 0;
  border: unset;
  font-size: 2rem;
  cursor: pointer;
  box-shadow: var(--box-shadow2);
  right: 3rem;
  top: 1rem;
}

@media screen and (width < 880px) {
  .artist-card {
    height: 65vh;
  }

  .artist-card button {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
}
