#register-form-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: row;
  background-color: var(--blue-dark);
  align-items: center;
  flex-direction: column;
}

#register-form-wrapper > form {
  min-width: 60vw;
}

#register-form-navigation-box {
  padding: 3rem 0 1rem 0;
}

#register-form-navigation-box > ul {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}

#register-form-navigation-box > ul > li {
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  line-height: 0;
  border-radius: 50%;
  transition: 0.3s all ease;
  color: var(--blue-light);
}

#register-form-navigation-box > ul > li:hover {
  background-color: var(--blue-m);
  color: var(--blue-lighter);
}

#registered-confirmation-wrapper {
  width: 60%;
  padding: 3rem;
  display: grid;
  margin: auto;
}

#registered-confirmation-wrapper > h2 {
  margin-bottom: 1rem;
  color: var(--contrast-orange);
}

#registered-confirmation-wrapper > h3 {
  color: var(--blue-light);
}

#registered-confirmation-wrapper > h3:hover {
  color: white;
}

@media screen and (width < 670px) {
  #register-form-navigation-box {
    padding: 0 1rem 0 0;
  }

  #registered-confirmation-wrapper {
    width: 80%;
  }
}
