.devlog {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 1rem 2rem 3rem 2rem;
  border-radius: 1rem;
  background-color: whitesmoke;
  box-shadow: var(--boxShadow);
  margin-bottom: 1rem;
  max-height: 50vh;
}

.devlog > h3 {
  color: rgb(0, 0, 0);
  display: block;
  font-size: 2.5rem;
  line-height: 2.25rem;
  color: var(--blue-dark);
  border-bottom: 0.1rem solid var(--blue-dark);
  padding: 0 0 0.5rem 0;
}

.devlog > p {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--blue-dark);
  overflow: hidden;
}

.devlog > .devlog-timestamp {
  color: rgba(0, 0, 0, 0.212);
}

.devlog > nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.devlog > nav > button {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.7rem;
  border: unset;
  color: var(--blue-m);
  align-items: center;
}

.devlog > nav > button:hover {
  background-color: var(--blue-dark);
  color: var(--blue-light);
}

.devlog > nav > button:nth-child(1) {
  font-size: 1.25em;
  line-height: 0;
  color: var(--blue-light);
}

.devlog > nav > button:nth-child(2) {
  font-size: 1.5rem;
  line-height: 0;
  color: var(--contrast-orange);
}

@media screen and (width < 880px) {
  .devlog {
    min-height: 65vh;
  }
}
