#edit-event-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
}

#edit-event-form {
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow2);
  padding: 3rem 5rem;
  margin: 2rem 0;
  border-radius: 1rem;
}

#edit-event-form > h3 {
  font-size: 2rem;
  color: var(--blue-m);
}

#edit-event-form > label {
  font-size: 1rem;
  margin-left: 0.5rem;
  color: var(--contrast-orange);
  margin: 1.25rem 0 0.5rem 0.3rem;
}

#edit-event-form > input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 0.075rem solid var(--blue-light);
  /* box-shadow: var(--box-shadow2); */
  width: 65vw;
  color: rgba(0, 0, 0, 0.626);
}

#edit-event-form > input:focus {
  border: 1px solid var(--blue-m);
  color: black;
}

#edit-event-form > textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 0.075rem solid var(--blue-light);
  /* box-shadow: var(--box-shadow2); */
  min-width: 65vw;
  max-width: 65vw;
  min-height: 15vh;
  color: rgba(0, 0, 0, 0.4);
}

#edit-event-form > textarea:focus {
  border: 1px solid var(--blue-m);
  color: black;
}

#edit-event-form > button {
  margin: 3rem 0;
  width: 6rem;
  padding: 0.7rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  background-color: var(--blue-m);
  color: white;
  border: unset;
}

#edit-event-form > button:hover {
  background-color: var(--contrast-orange);
}

@media screen and (width < 900px) {
  #edit-event-form > textarea {
    min-height: 40vw;
  }
  #edit-event-form {
    padding: 2rem 4rem;
  }
}
