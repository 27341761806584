#loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  width: 80%;
  align-self: center;
  margin: 2rem 0;
  height: 25vh;
  box-shadow: var(--box-shadow2);
}

#loading-wrapper > span {
  width: 80%;
}
