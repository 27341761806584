#logo-box {
  display: flex;
  gap: 1rem;
  align-items: center;
}

#company-logo-image {
  height: 80px;
  width: 100px;
  background-image: url("../../static/logo.png");
  background-size: cover;
  margin: 1rem 0;
}

#logo-box > h2 {
  font-family: "Oswald";
  color: var(--blue-dark);
}

@media screen and (width < 1100px) {
  #logo-box {
    gap: 0.1rem;
  }
  #logo-box > h2 {
    font-size: 2rem;
  }

  #company-logo-image {
    height: 50px;
    width: 70px;
  }
}
