#event-reel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#event-reel-wrapper > h2 {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: var(--box-shadow);
  margin-top: 2rem;
}

#event-reel-wrapper > h3 {
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  text-shadow: var(--box-shadow2);
}

#event-reel {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 3rem;
  gap: 1rem;
  margin: 2rem 0;
}

#event-reel article {
  min-height: 25vh;
}

#load-more-events {
  display: flex;
  width: 100vw;
  justify-content: center;
}

#load-more-events > button {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--contrast-orange);
  color: white;
  border: unset;
  cursor: pointer;
  margin-bottom: 2rem;
}

#load-more-events > button:hover {
  background-color: var(--blue-m);
  color: var(--blue-lighter);
}

@media screen and (width < 1100px) {
  #event-reel {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (width < 880px) {
  #event-reel {
    padding: 0 1rem;
    grid-template-columns: 1fr 1fr;
  }
  #load-more-events {
    margin-top: 2rem;
  }
}

@media screen and (width < 660px) {
  #event-reel {
    grid-template-columns: 1fr;
  }
}

@media screen and (width < 600px) {
  #event-reel-wrapper > h2 {
    font-size: 1.5rem;
  }
}