footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-dark);
  background-image: linear-gradient(var(--blue-dark), var(--blue-m));
  padding-bottom: 3rem;
}

.footer-separator {
  display: flex;
  height: 1px;
  background-color: white;
  width: 90vw;
}

#footer-helpful-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10vw;
  width: 100%;
  padding: 2rem 10vw;
  color: white;
}

#footer-helpful-links > ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
#footer-helpful-links > ul > li {
  font-size: 1rem;
}
#footer-helpful-links > ul > li:nth-child(1) {
  font-size: 1.5rem;
}

#social-media {
  color: white;
}
#social-media > h5 {
  margin-top: 1rem;
  color: white;
}

#social-media {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem 0 2.25rem 0;
  gap: 1rem;
  cursor: pointer;
}

#social-media > li {
  font-size: 2rem;
}

@media screen and (width < 880px) {
  #footer-helpful-links {
    grid-template-columns: 1fr 1fr;
  }

  #navigate-to-top > span {
    font-size: 1.25rem;
  }

  #social-media {
    gap: unset;
  }
}
