#purchase-modal-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  top: 15vh;
  background-color: var(--blue-dark);
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 49;
}

#purchase-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 95%;
  background-color: var(--blue-light);
  z-index: 50;
  padding: 10rem;
  border-radius: 1rem;
}

#purchase-modal > h1 {
  font-size: 3rem;
  margin-bottom: 3rem;
}

#purchase-modal > h2 {
  font-size: 2rem;
  margin-top: 2rem;
}

/* CLOSE MODAL BUTTON */
#purchase-modal > button {
  position: absolute;
  top: 3rem;
  right: 3rem;
  line-height: 0;
  background-color: white;
  border: unset;
  font-size: 2.5rem;
  cursor: pointer;
  color: var(--blue-dark);
  border-radius: 50%;
  padding: 0.25rem;
}

#purchase-modal > button:hover {
  color: var(--contrast-orange);
}

#purchase-modal-grid {
  background-color: white;
  padding: 2rem 4rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow2);
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 0.5rem;
  width: 100%;
}

#purchase-modal-grid > label {
  color: rgba(0, 0, 0, 0.75);
}

#purchase-modal-button-box {
  /* background-color: bisque; */
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  max-height: 4rem;
  margin: 2rem 4rem;
  width: 100%;
}

#purchase-modal-button-box > button {
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  border: unset;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  font-size: 1.25rem;
  color: white;
  width: 100%;
  justify-self: center;
  min-width: 25%;
  max-width: 300px;
}

#purchase-modal-button-box > a {
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  border: unset;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  font-size: 1.25rem;
  color: white;
  background-color: var(--blue-m);
  text-align: center;
  justify-self: center;
  width: 100%;
  max-width: 300px;
}

#purchase-modal-button-box > a:hover {
  background-color: white;
  outline: 0.1rem solid var(--blue-m);
  color: var(--blue-m);
}

#purchase-modal-button-box > button:nth-child(1) {
  background-color: var(--blue-light);
}

#purchase-modal-button-box > button:nth-child(1):hover {
  background-color: white;
  outline: 0.1rem solid var(--blue-m);
  color: var(--blue-m);
}

#purchase-modal-button-box > button:nth-child(2) {
  background-color: var(--blue-dark);
}

#purchase-modal-button-box > button:nth-child(2):hover {
  background-color: var(--contrast-orange);
}

@media screen and (width < 1100px) {
  #purchase-modal-button-box {
    width: 90%;
    height: 5vh;
  }

  #purchase-modal > button {
    font-size: 1.5rem;
  }

  #purchase-modal {
    padding: 1rem 1.5rem;
  }

  #purchase-modal-grid {
    margin-top: 3rem;
    padding: 1.5rem 1rem;
  }

  #purchase-modal-button-box {
    width: 90%;
    min-height: 5vh;
  }
}

@media screen and (width < 900px) {
  #purchase-modal > button {
    font-size: 2rem;
    top: 1rem;
    right: 1rem;
  }

  #purchase-modal-grid {
    width: 90%;
    margin-top: 0;
    grid-template-columns: 1fr;
  }

  #purchase-modal-grid > label {
    font-size: 1.25rem;
  }

  #purchase-modal-grid > p {
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.05rem;
  }

  #purchase-modal {
    padding: 4rem 3rem;
  }

  #ticket-price-span > span {
    grid-template-columns: 1fr;
  }
}

@media screen and (width < 660px) {
  #purchase-modal-button-box {
    flex-direction: column;
    padding: 3rem 0 0 0;
  }
}
