#add-event-form-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--blue-light);
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

#add-event-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

#add-event-form > h3 {
  color: var(--blue-m);
}
#add-event-form > input {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  box-shadow: var(--box-shadow2);
  border: unset;
}
#add-event-form > input:focus {
  font-weight: 600;
}

#add-event-form > label {
  padding: 0.2rem 0.2rem;
  color: var(--blue-dark);
  font-size: 1rem;
  font-weight: 600;
}

#add-event-form > textarea {
  min-width: 60vw;
  max-width: 60vw;
  padding: 0.5rem;
  min-height: 3rem;
  border: unset;
  box-shadow: var(--box-shadow2);
  border-radius: 0.5rem;
}

#add-event-form > input:focus {
  outline: none;
  /* background-color: var(--blue-m); */
}

#add-event-form > button {
  background-color: var(--blue-dark);
  margin: 1rem 2rem;
  max-width: 60%;
  align-self: center;
  padding: 0.75rem 3rem;
  border-radius: 1rem;
  color: white;
  box-shadow: var(--box-shadow2);
}

#add-event-form-navigation-box {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30vh;
  left: 5vw;
  z-index: 3;
}

#add-event-form-navigation-box > ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#add-event-form-navigation-box > ul > li {
  font-size: 2rem;
  color: var(--blue-m);
  line-height: 0;
  padding: 0.75rem;
  border-radius: 2rem;
  background-color: var(--blue-lighter);
}

#add-event-form-navigation-box > ul > li:hover {
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  background-color: var(--blue-lighter);
  color: var(--blue-dark);
}

#submitted-event-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30vh;
}

#submitted-event-wrapper > h1 {
  font-size: 3rem;
  color: var(--blue-dark);
}

#submitted-event-wrapper > p {
  font-weight: 600;
  font-size: 2rem;
}

#submitted-event-wrapper > p:nth-child(3) {
  color: var(--blue-m);
  line-height: 0;
}

#submitted-event-wrapper > p:nth-child(3):hover {
  color: white;
}
#artists-modal-wrapper {
  display: flex;
  position: absolute;
  z-index: 5;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: black/30;
  backdrop-filter: blur(2px);
}

#artists-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  flex-direction: column;
}

#artists-modal > span {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 70%;
  min-height: 60%;
  position: relative;
  background-color: white;
  padding: 5rem 3rem;
  border-radius: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

#artists-modal > span > button:nth-child(1) {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: var(--blue-m);
  border: unset;
  cursor: pointer;
  line-height: 0;
  background-color: transparent;
}

#artists-modal > button {
  /* position: absolute;
  bottom: 1rem;
  left: calc(50%-2rem); */
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--blue-m);
  color: white;
  border: unset;
  cursor: pointer;
  margin-top: -4rem;
  z-index: 30;
  outline: 0.25rem solid white;
}

#select-cast-button {
  box-shadow: var(--box-shadow2);
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--blue-dark);
  color: white;
  border: unset;
  cursor: pointer;
  margin: 1rem 0;
}
/* RESPONSIVE  */

@media screen and (width < 950px) {
  #artists-modal > span {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (width < 760px) {
  #add-event-form {
    margin-left: 4rem;
  }

  #add-event-form > textarea {
    min-width: 70vw;
    min-height: 40vh;
  }

  #add-event-form-navigation-box {
    left: 2vw;
  }

  #artists-modal > span {
    width: 90%;
  }
}
@media screen and (width < 580px) {
  #artists-modal > span {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (width < 470px) {
  #artists-modal > span {
    grid-template-columns: repeat(2, 1fr);
  }
}