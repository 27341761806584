#delete-devlog-wrapper {
  padding: 2rem 0;
}

#delete-devlog-wrapper > h3 {
  font-size: 3rem;
  padding: 1rem;
}

#delete-devlog-btn-box > button {
  border: unset;
  background-color: var(--blue-m);
  font-size: 2rem;
  margin-left: 1rem;
  border-radius: 1rem;
  color: var(--blue-light);
  padding: 1rem;
}

#delete-devlog-btn-box > button:nth-child(2) {
  background-color: var(--blue-dark);
}

#delete-devlog-btn-box > button:nth-child(2):hover {
  background-color: var(--contrast-orange);
  color: var(--blue-lighter);
}

#delete-devlog-btn-box > button:hover {
  background-color: var(--contrast-orange);
  color: var(--blue-lighter);
}
