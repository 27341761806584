* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Oswald;
  src: url("./fonts/Oswald/Oswald-Regular.ttf");
}

@font-face {
  font-family: Nunito-Bold;
  src: url("./fonts/NunitoSans/NunitoSans_10pt-Bold.ttf");
}

@font-face {
  font-family: Nunito-Medium;
  src: url("./fonts/NunitoSans/NunitoSans_10pt-Medium.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: Nunito-Bold;
}

p {
  font-family: Nunito-Medium;
}

button,
li,
label,
textarea,
a {
  font-family: Nunito-Medium;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style-type: none;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit !important;
}

a:visited {
  color: inherit;
}

input:focus {
  outline: unset;
}

textarea::-webkit-scrollbar {
  width: 0.5rem;
}

/* ERROR PAGES */

#error-page {
  display: flex;
  margin-top: 30vh;
  gap: 2rem;
  align-items: center;
  flex-direction: column;
}

.submitted-form-space > p:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

/* color swatches */

:root {
  --box-shadow: rgba(17, 17, 26, 0.1) 0px 0.1rem 0px,
    rgba(17, 17, 26, 0.1) 0 0.5rem 1rem, rgba(17, 17, 26, 0.1) 0px 0.75rem 2rem;
  --box-shadow2: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  --textColor: rgb(181, 219, 237);
  --bg: -webkit-linear-gradient(rgb(42, 42, 42), rgb(0, 0, 0));
  --blue-m: #425a7d;
  --blue-light: #a0c0d6;
  --blue-lighter: #e5fafc;
  --contrast-orange: #df7356;
  --blue-dark: #2a3240;
}

/* this is for components that will have their views extended to full screen */
/* related to devlog component, */
.extended {
  max-height: auto;
  min-height: 85vh;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
  outline: none !important;
}