#carousel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#carousel-wrapper > h2 {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: var(--box-shadow);
  margin-top: 2rem;
}

#carousel-wrapper > h3 {
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  text-shadow: var(--box-shadow2);
}

#inner-carousel {
  display: flex;
}

#carousel {
  cursor: grab;
  margin: 1rem 1rem 3rem 1rem;
}
