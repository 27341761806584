#ticket-card-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr 0.1fr;
  background-color: white;
  width: 80vw;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

#ticket-info {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1rem;
  background-image: linear-gradient(90deg, white, var(--blue-light));
  padding: 2rem;
  border-radius: 1rem;
}

#qr-code-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

#ticket-info > span > h3 {
  margin-bottom: 1.5rem;
}

#ticket-info > span > h4 {
  margin-bottom: 1.5rem;
}

@media screen and (width < 860px) {
  #ticket-card-wrapper {
    width: 90vw;
    grid-template-columns: 2fr 1fr 0.2fr;
  }
}

@media screen and (width < 750px) {
  #ticket-info {
    grid-template-columns: 1fr;
  }
}

@media screen and (width < 660px) {
  #ticket-card-wrapper {
    grid-template-columns: 1fr;
  }

  #ticket-card-wrapper > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
  #ticket-card-wrapper > div:nth-child(3) > svg {
    font-size: 2.5rem;
  }
}
