#login-form-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--blue-light);
  align-items: center;
}

#login-form-wrapper > form {
  max-width: 600px;
  padding: 0 2rem;
}

#login-form-navigation-box {
  padding: 3rem 0 1rem 0;
}

#login-form-navigation-box > ul {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}

#login-form-navigation-box > ul > li {
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  line-height: 0;
  border-radius: 50%;
  transition: 0.3s all ease;
  color: var(--blue-dark);
}

#login-form-navigation-box > ul > li:hover {
  background-color: var(--blue-m);
  color: var(--blue-lighter);
}

@media screen and (width < 670px) {
  #login-form-wrapper {
    flex-direction: column;
  }

  #login-form-navigation-box {
    padding: unset;
    margin-top: 1rem;
  }

  #login-form-navigation-box > ul {
    flex-direction: row;
  }

  #login-success-wrapper {
    padding: 0 2rem;
  }
}
